const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class LoadMoreList {
  constructor(component) {
    const loadMoreSection = $(".load-more", component);
    const items = $$("div[itemprop='itemListElement']", component);
    const displayAmount = $("[data-count]") ? $("[data-count]").getAttribute("data-count")  : 9;

    this.displayAmount = displayAmount;
    this.loadMoreSection = loadMoreSection;
    this.items = items;
    this.revealItems = this.revealItems.bind(this);
    this.initLoadMore = this.initLoadMore.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.component = component;

    if (loadMoreSection && items.length > displayAmount) {
      loadMoreSection.classList.remove("hidden");
      this.initLoadMore(items);
      $("button", loadMoreSection).addEventListener("click", this.loadMore);
    }
  }

  initLoadMore(targetedItems) {
    this.items.forEach((item,index) => {
      if (index >= this.displayAmount) {
        item.classList.add("hidden");
      }
    });
  }

  loadMore() {
    let hiddenItems = $$("div[itemprop='itemListElement'].hidden", this.component);
    let items = Array.prototype.slice.call(hiddenItems, 0, this.displayAmount);
    this.revealItems(items);
  }

  revealItems(items) {
    let slicedItems = items.slice(0, this.displayAmount);

    slicedItems.forEach(item => {
      item.classList.remove("hidden");
    })

    if (items.length <= this.displayAmount) {
      this.loadMoreSection.classList.add("hidden");
    } else {
      this.loadMoreSection.classList.remove("hidden");
    }
  }
}

$$(".limited")?.forEach(list => {
  new LoadMoreList(list);
});

export default LoadMoreList;
