import cssHasPseudo from "../../node_modules/@csstools/css-has-pseudo-experimental/dist/browser.mjs";
import "./imports.js";
import "./modal.js";
import "./button-background.js";

cssHasPseudo(document);

/**
 * Fixes scroll-smooth not working in Chrome.
 */
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    const target = document.querySelector(this.getAttribute('href'));
    if (!target) return;
    // Pass through if the target is a modal.
    if (target.matches("dialog")) return;

    e.preventDefault();

    target.scrollIntoView({
      behavior: 'smooth'
    });
  });
});
