const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const shareTool = $(".article-share-tool");

if (shareTool) {
  let url = document.location.href;
  let title = document.title;

  $(".article-share-tool .facebook")?.setAttribute("href",`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`);
  $(".article-share-tool .twitter")?.setAttribute("href",`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`);
  $(".article-share-tool .linkedin")?.setAttribute("href",`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`);
  $(".article-share-tool .copy").addEventListener("click", (e)=> {
    e.preventDefault();
    navigator.clipboard.writeText(window.location.href).then(() => {
      alert("Copied page URL to clipboard");
    })
  });
  $(".article-share-tool .print").addEventListener("click", (e) => {
    e.preventDefault();
    window.print();
  });
}
