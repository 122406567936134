import { gsap } from "gsap/dist/gsap.min";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const uppers = gsap.utils.toArray("div[data-gs='up']");
const downers = gsap.utils.toArray("div[data-gs='down']");

let mm = gsap.matchMedia();

mm.add("(min-width: 768px)", () => {
  
  uppers.forEach((upper, i) => {
    gsap.to(upper, {
      scrollTrigger: { trigger: upper, start: "bottom 90%", end: "top 0%", scrub: 0 },
      y: -100
    });
  });
  
  downers.forEach((downer, i) => {
    gsap.to(downer, {
      scrollTrigger:{ trigger: downer, start: "bottom 90%", end: "top 00%", scrub: 0 },
      y: 100
    });
  });

});


