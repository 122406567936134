const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class FilterList {
  constructor(component, options = {}) {
    const filterControls = $$("select.filter-control", component);
    let items = $$("div[itemprop='itemListElement']", component.parentNode);
    const displayAmount = $("[data-count]") ? $("[data-count]").getAttribute("data-count") : 9;
    const skipAmount = $("[data-skip]") ? $("[data-skip]").getAttribute("data-skip") : 0;
    const loadMoreSection = $(".load-more", component.parentNode);

    this.filterControls = filterControls;
    this.items = items;

    this.displayAmount = displayAmount;
    this.loadMoreSection = loadMoreSection;

    this.revealItems = this.revealItems.bind(this);
    this.filterItems = this.filterItems.bind(this);

    this.initLoadMore = this.initLoadMore.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.uniqueOptions = this.uniqueOptions.bind(this);

    this.component = component;

    let filterItems = this.filterItems;

    for (let i=0; i < skipAmount; i++) {
      items[i].remove();
    }

    // refresh items from what's in the DOM.  Is there a better way to do this?
    items = $$("div[itemprop='itemListElement']", component.parentNode);

    filterControls.forEach(select => {
      this.uniqueOptions(select);
      // Sorting done in xsl, add here if it needs to change.
      select.addEventListener('change', () => {
        this.filterItems();
      })
    })

    if (loadMoreSection && items.length > displayAmount) {
      this.initLoadMore(items);
      loadMoreSection.classList.remove("hidden");
      $("button", loadMoreSection).addEventListener("click", this.loadMore);
    }

    //Catch browsers retained  selection on back button hit
    setTimeout(() => { filterItems(); }, 200);
  }

  uniqueOptions(select) {
    [].slice.call(select.options)
      .map(function (a) {
        if (this[a.innerText]) {
          select.removeChild(a);
        } else {
          this[a.innerText] = 1;
        }
      }, {});
  }

  filterItems() {
    let items = this.items;

    items.forEach(item => {
      item.classList.remove("filtered");
      item.classList.remove("revealed");
    })

    this.filterControls.forEach(select => {
      let name = select.getAttribute("data-keyword");
      let value = select.value;

      if (value !== 'on') {
        items.forEach(item => {
          let content = item.getAttribute(`data-${name}`);
          if (content.indexOf(value) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    })

    if (this.loadMoreSection) {
      let remainingItems = $$("div[itemprop='itemListElement']:not(.filtered)", this.component.parentNode);
      remainingItems = Array.prototype.slice.call(remainingItems);
      this.revealItems(remainingItems);
    }
  }

  initLoadMore(targetedItems) {
    this.items.forEach(item => {
      item.classList.remove("hidden");
    })

    if (targetedItems.length > this.displayAmount) {

      targetedItems.forEach((item, index) => {
        if (index >= this.displayAmount) {
          item.classList.add("hidden");
        }
      })

      this.loadMoreSection.classList.remove("hidden");
    } else {
      this.loadMoreSection.classList.add("hidden");
    }
  }

  loadMore() {
    let hiddenNotFiltered = $$("div[itemprop='itemListElement']:not(.revealed):not(.filtered)", this.component.parentNode);
    let items = Array.prototype.slice.call(hiddenNotFiltered, 0, this.displayAmount);
    this.revealItems(items);
  }

  revealItems(items) {
    let slicedItems = items.slice(0, this.displayAmount);

    slicedItems.forEach(item => {
      item.classList.add("revealed");
    })

    if (items.length <= this.displayAmount) {
      this.loadMoreSection.classList.add("hidden");
    } else {
      this.loadMoreSection.classList.remove("hidden");
    }
  }
}

const filterForms = document.querySelectorAll(".filters");
for (form of filterForms) {
  new FilterList(form, {});
}

export default FilterList;
