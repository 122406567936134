const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

$("#mobile-menu-button").addEventListener("click", function(event) {
  const button = event.target;
  const buttonSR = button.querySelector(".sr-only");

  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].platforma-site-header");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  buttonSR.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body").classList.toggle("overflow-hidden");
});

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".platforma-site-header:not(.menu-open) a + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function (child) {
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    closeMenu(item);
  })

  if ($navItem.classList.contains("open")) {
    closeMenu($navItem);
  } else {
    openMenu($navItem);
  }
}, false);

document.addEventListener('click', function (event) {
  const targets = `
    .platforma-site-header.menu-open nav .isParent > a,
    .platforma-site-header.menu-open nav .isParent > span,
    .platforma-site-header.menu-open nav .isGrandParent > a,
    .platforma-site-header.menu-open nav .isGrandParent > span,
    .platforma-site-header.menu-open nav .isParent > button,
    .platforma-site-header.menu-open nav .isGrandParent > button
  `

  const closest = event.target.closest(targets);
  if (!closest) return;

  const $navItem = closest.parentNode;

  const primaryChildren = $$("header nav[aria-label='Primary'] > ul > li");
  const utilityChildren = $$("header nav[aria-label='Utility'] > div > div > ul > li");
  const selectedSiblings = [...primaryChildren, ...utilityChildren];

  let siblings = Array.prototype.filter.call(selectedSiblings, function (child) {
    return child !== $navItem;
  });

  event.preventDefault();

  if ($navItem.classList.contains(`open`)) {
    closeMenu($navItem);
  } else {
    openMenu($navItem);
    siblings.forEach(sibling => {
      closeMenu(sibling);
    })
  }
}, false);

function openMenu(parent) {
  const button = $("button", parent);

  parent?.classList.add("open");
  button?.setAttribute(`aria-expanded`, `true`);
}

function closeMenu(parent) {
  const button = $("button", parent);

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}
