
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

(function() {
  const affiliateForm = $("#affiliate-form form");

  if (affiliateForm) {
    let active = "contact";
    const fieldSets = ["contact", "agency", "documents"];
    const numberOfStates = fieldSets.length;

    const fieldsetElements = fieldSets.map((fieldset) => {
      return $(`#${fieldset}-info`);
    });

    fieldsetElements.forEach((fieldset, index) => {
      const button = $("button",fieldset);
      if (button) {
        button.addEventListener("click", function(event) {
          event.preventDefault();

          nextState();
        });
      }
    });

    affiliateForm.addEventListener("onkeydown", function(event) {
      let keycode = event.keyCode;
      if (keycode === 13) {
        event.preventDefault();
        nextState();
      }
    });

    document.addEventListener("click", function(event) {
      const target = event.target;
      if (!target.classList.contains("edit")) {
        return;
      }
      event.preventDefault();
      const $next = target.getAttribute(`data-next`);

      if($next) {
        editState($next);
      }
    });

    function editState(state) {
      // Hide all the fieldSets and show all the Revs
      fieldSets.forEach((fieldset, index) => {
        let set = $(`#${fieldset}-info`);
        let rev = $(`#${fieldset}-review`);
        set.classList.add('hidden');
        rev.classList.remove('hidden');
      });

      // Show the next fieldSet and hide the next rev
      let nextSet = $(`#${state}-info`);
      let nextRev = $(`#${state}-review`);
      nextSet.classList.remove('hidden');
      nextRev.classList.add('hidden');

      $("input", nextSet).focus();

      active = state;
    }

    function nextState() {
  
      const currentButton = $(`#${active}-info button`);
      const $next = currentButton.getAttribute(`data-next`);
      const $validate = currentButton.getAttribute(`data-validate`).split(",");
  
      // Validate the fields
      let allValid = true;
      $validate.reverse().forEach((field) => {
        let $input = $(`input[name="${field}"]`);
        if(!$input.checkValidity()) {
          $input.reportValidity();
          allValid = false;
        };
      });
  
      if (!allValid) {
        return;
      }

      if (fieldSets.indexOf(active) === numberOfStates - 1) {
        affiliateForm.submit();
        return;
      }
  
      updateRev(active);
  
      // Hide all the fieldSets and show all the Revs
      fieldSets.forEach((fieldset, index) => {
        let set = $(`#${fieldset}-info`);
        let rev = $(`#${fieldset}-review`);
        set.classList.add('hidden');
        rev.classList.remove('hidden');
      });
  
      // Show the next fieldSet and hide the next rev
      let nextSet = $(`#${$next}-info`);
      let nextRev = $(`#${$next}-review`);
      nextSet.classList.remove('hidden');
      nextRev.classList.add('hidden');

      $("input", nextSet).focus();
  
      active = $next;
    }
  
    function updateRev() {
  
      // List of fields for each fieldset
      const fields = {
        "contact": ["first", "last","phone","email"],
        "agency": ["agency-name", "city","state","website","commercial-lines","personal-lines","revenue","employees"],
        "documents": ["license", "eoproof","nda","w9"]
      };
  
      // Loop through the fieldsets, concatenate the getValue() of each field on the active fieldset
      Object.keys(fields).forEach((fieldset, index) => {
        if (fieldset === active) {
          $(`#${fieldset}-review`).innerHTML = fields[fieldset].reduce((acc, field) => {
            return acc + `${getValue(field)}<br/>`;
          }, `<p><div class="float-right"><a data-next="${fieldset}" href="#" class="edit">Edit</a></div>`)+"</p>";
        }
      });
    }
  
    // Return the value or data-when-empty attribute
    function getValue(field) {
      let $input = $(`input[name="${field}"]`);
      return $input.value || $input.getAttribute("data-when-empty");
    }
  }
})();
