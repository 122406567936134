let btns = document.querySelectorAll('.button, .cta');
btns.forEach(btn => {
  
  // Wrap the text in a span
  let text = btn.innerText;
  btn.innerHTML = '';
  let span = document.createElement('span');
  span.innerText = text;
  btn.appendChild(span);

  btn.addEventListener('mousemove', e => {
    let rect = e.currentTarget.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    btn.style.setProperty('--x', x + 'px');
    btn.style.setProperty('--y', y + 'px');
  });

});

